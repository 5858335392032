<template>
    <ModuleWrapper 
        :pageTitle="pageTitle"
        :bodyPadding="$route.name === 'projects-gant' ? false : true"
        :headerBg="!isMobile"
        :pageRoutes="routes">
        <template v-if="!isMobile" v-slot:h_left>
            <PageFilter
                :model="pageModel"
                :key="initPageName"
                :excludeFields="excludeFields"
                size="large"
                :page_name="initPageName" />
        </template>
        <template v-if="!isMobile" v-slot:h_right>
            <a-button 
                v-if="addTemplateButton && addButton"
                class="header__button"
                icon="plus" 
                size="large"
                type="primary"
                ghost
                @click="createTemplateHandler()" >
                {{ addTemplateButton.label ? addTemplateButton.label : $t(this.templateButtonText) }}
            </a-button>
            <a-button 
                v-if="addButton"
                class="header__button"
                icon="plus" 
                size="large"
                type="primary"
                @click="createHandler()" >
                {{ addButton.label ? addButton.label : $t(this.buttonText) }}
            </a-button>
            <component
                :is="settingsButtonWidget"   
                :pageName="initPageName"
                class="ml-2" />
        </template>
        <router-view 
            :tableType="tableType"
            :pageModel="pageModel"
            :page_name="initPageName"
            :buttonSize="buttonSize"
            :buttonText="buttonText"
            :templateButtonText="templateButtonText"
            :pageConfig="pageConfig" />
    </ModuleWrapper>
</template>

<script>
import SettingsButton from '@/components/TableWidgets/SettingsButton'
import PageFilter from '@/components/PageFilter'
import ModuleWrapper from '@/components/ModuleWrapper/index.vue'
import routes from './config/router.js'
export default {
    components: {
        PageFilter,
        SettingsButton,
        ModuleWrapper
    },
    props: {
        tableType: {
            type: String,
            default: 'projects'
        },
        pageModel: {
            type: String,
            default: 'workgroups.WorkgroupModel'
        },
        page_name: {
            type: String,
            default: 'page_list_projects.WorkgroupModel'
        },
        buttonSize: {
            type: String,
            default: 'large'
        },
        buttonText: {
            type: String,
            default: 'project.add_project'
        },
        templateButtonText: {
            type: String,
            default: 'project.templates'
        },
        pageConfig: {
            type: Object,
            default: () => null
        }
    },
    computed: {
        initPageName() {
            if(this.$route.name === 'projects-gant')
                return this.page_name + '_gant'
            return this.page_name
        },
        excludeFieldsKey() {
            return this.excludeFields.length ? 'true' : 'false'
        },
        excludeFields() {
            if(this.$route.name === 'projects-gant')
                return ['dead_line', 'dead_line__exclude']
            return []
        },
        pageTitle() {
            return this.$route?.meta?.title || ''
        },
        isMobile() {
            return this.$store.state.isMobile
        },
        settingsButtonWidget() {
            return () => import(/* webpackMode: "lazy" */'@/components/TableWidgets/SettingsButton')
        },
        createButton() {
            return this.pageConfig?.headerButtons?.createButton || null
        },
        getRouteInfo() {
            return this.$store.getters['navigation/getRouteInfo']('projects')
        },  
        addButton() {
            if(this.getRouteInfo?.pageActions?.add) 
                return {
                    label: this.getRouteInfo?.buttonConfig?.label || this.$t(this.buttonText)
                }
            return null
        },
        addTemplateButton() {
            if(this.getRouteInfo?.pageActions?.addTemplate) 
                return {
                    label: this.getRouteInfo?.templateButtonConfig?.label || this.$t(this.templateButtonText)
                }
            return null
        }
    },
    data() {
        return {
            routes
        }
    },
    methods: {
        createTemplateHandler() {
            this.$router.replace({
                query: { createProjectTemplate: true }
            })
        },
        testCreateHandler() {
            this.$router.replace({
                query: { createProject: true }
            })
        },
        createHandler() {
            this.$router.replace({
                query: { create_project: true }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.header__button + .header__button {
    margin-left: 10px;
}
</style>